.loading-container {
  background-color: transparent;
  .loading-main {
    margin: 20rem 50rem;
    display: inline-block;

    .loading-logo {
      display: inline-block;
      width: 80rem;
      height: 80rem;
      background-image: url(./assets/logo-yakinku.png);
    }
  }

  .loading-spin-y {
    .loading-logo {
      animation: lds-circle 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  .loading-bounce {
    .loading-logo {
      animation: ldx-bounce 1s infinite linear;
    }
  }

  .loading-beat {
    .loading-logo {
      animation: ldx-beat 1s infinite linear;
    }
  }

  .loading-spin-z {
    .loading-logo {
      animation: ldx-coin-v 2s infinite linear;
    }
  }

  .loading-cycle {
    .loading-logo {
      animation: ldx-cycle 2s infinite linear;
    }
  }

  .loading-flip {
    .loading-logo {
      animation: ldx-flip 1s infinite linear;
    }
  }
}

@keyframes ldx-flip {
  0% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(-1, 1);
  }
  50% {
    transform: scale(-1, -1);
  }
  75% {
    transform: scale(1, -1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes ldx-cycle {
  0% {
    animation-timing-function: cubic-bezier(0.3333, 0.3333, 0.6667, 0.6667);
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ldx-coin-v {
  0% {
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(3600deg);
  }
}

@keyframes ldx-beat {
  0% {
    animation-timing-function: cubic-bezier(0.1028, 0.2484, 0.1372, 0.849);
    transform: scale(1);
  }
  34% {
    animation-timing-function: cubic-bezier(0.7116, 0.2095, 0.8159, 0.6876);
    transform: scale(1.2);
  }
  68% {
    animation-timing-function: cubic-bezier(0.1475, 0.2888, 0.294, 0.883);
    transform: scale(1.0268);
  }
  84% {
    animation-timing-function: cubic-bezier(0.8176, 0.2193, 0.867, 0.6889);
    transform: scale(1.0932);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

@keyframes ldx-bounce {
  0% {
    animation-timing-function: cubic-bezier(0.138, 0.2541, 0.2177, 0.8747);
    transform: translate(0, 0) scaleY(1);
  }
  37% {
    animation-timing-function: cubic-bezier(0.7679, 0.1817, 0.8401, 0.7167);
    transform: translate(0, -39.96%) scaleY(1);
  }
  72.2% {
    animation-timing-function: cubic-bezier(0.1045, 0.2026, 0.2224, 0.9608);
    transform: translate(0, 0) scaleY(1);
  }
  87.2% {
    animation-timing-function: cubic-bezier(0.7463, 0.2314, 0.8159, 0.6941);
    transform: translate(0, 19.85%) scaleY(0.603);
  }
  100% {
    transform: translate(0, 0) scaleY(1);
  }
}
