.customized-input {
  display: block;
  width: 100%;
  padding: 0 15rem;
  border: 1rem solid var(--background);
  border-radius: var(--radius);
  transition: var(--transition);
  height: 40rem;

  &:focus-visible {
    outline: var(--active) solid 1rem;
  }
}
