.dropdown-list {
  background-color: white;
  border-radius: 10rem;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  transition: 0.1s linear padding, 0.1s linear margin;

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    transition: 0.1s linear height;
  }
}

.dropdown-list-open {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
  padding: 4rem 0;
  margin-top: 2rem;
  ul {
    height: 66rem;
  }
}

.dropdown-list-close {
  border: none;
  padding: 0;
  margin-top: 0;
  ul {
    height: 0;
  }
}
