.buttons {
  display: flex;
  justify-content: center;
  gap: 20rem;

  .customized-button {
    width: 150rem;
  }

  .back-button {
    border: 1rem solid var(--background);
    background: var(--white);
    color: var(--background);

    &:hover {
      border: 1rem solid var(--hover);
      background: var(--white);
      color: var(--hover);
    }

    &:active {
      &:hover {
        border: 1rem solid var(--active);
        background: var(--white);
        color: var(--active);
      }
    }
  }
}
