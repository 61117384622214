.custom-image {
  aspect-ratio: 1/1;

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  mask-image: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}
