.thanks {
  width: 100vw;
  height: calc(100dvh - 70rem);
  // background-image: url(./assets/404.gif);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20rem;
}
