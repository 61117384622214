.created {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.created-wrap {
  display: flex;
  height: calc(100dvh - 70rem);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  .created-btn {
    height: 40rem;
    width: 320rem;
    padding: 10rem;
    font-size: 14rem;
    font-weight: 700;
    margin: 30rem 16rem;
    border-radius: 5rem;
  }
}

.cancel-btns {
  gap: 0;

  .back-button {
    border-bottom-right-radius: 0;
    border-left: none;
    border-bottom: none;
  }

  .customized-button {
    &:last-child {
      border-bottom-left-radius: 0;
    }
  }
}
