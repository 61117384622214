.dropdown-placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .dropdown-placeholder-icon {
    border-top: 4rem solid var(--background);
    border-right: 4rem solid var(--white);
    border-left: 4rem solid var(--white);
  }

  .dropdown-placeholder-icon-open {
    transform: rotateZ(180deg);
  }
}
