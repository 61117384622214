.not-found {
  width: 100vw;
  height: calc(100dvh - 70rem);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20rem;
}
