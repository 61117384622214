.language-item {
  display: flex;
  align-items: center;
  gap: 8rem;
  font-size: 14rem;

  .language-item-flag {
    width: 18rem;
    height: 12rem;
  }
}

.language-vn {
  background-image: url(./assets/vn.svg);
}

.language-en {
  background-image: url(./assets/en.svg);
}
