.custom-dropdown {
  position: relative;
  height: 28rem;

  .dropdown-button {
    width: 150rem;
    height: 28rem;
    border-radius: 14rem;
    background-color: var(--white);
    padding: 0 16rem;

    .dropdown-placeholder-selected {
      color: var(--black);
    }

    .dropdown-placeholder-unselected {
      color: var(--disabled-color);
    }

    &:hover {
      background-color: var(--white);

      .dropdown-placeholder-selected {
        color: var(--black);
      }
      .dropdown-placeholder-icon {
        border-top-color: var(--hover);
      }
    }

    &:active {
      background-color: var(--white);

      .dropdown-placeholder-selected {
        color: var(--active);
      }
      .dropdown-placeholder-icon {
        border-top-color: var(--active);
      }
    }
  }
}
