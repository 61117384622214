.layout-header {
  height: 70rem;
  background-color: #000000;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20rem;

  .layout-header-logo {
    height: 50rem;
    width: 125rem;
    background-image: url(./assets/logo-top.png);
    cursor: pointer;
  }
}
