.info {
  height: calc(100dvh - 70rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .info-logo {
    width: 200rem;
    padding: 20rem 16rem;
    padding-bottom: 40rem;
  }

  .info-inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10rem;

    .customized-input {
      width: 300rem;
    }
  }

  .info-btn {
    margin: 30rem 16rem;
    .customized-button {
      width: 300rem;
    }
  }
}

.info-term {
  color: var(--blue);
  text-decoration: underline;
}

.info-logo {
  width: 200rem;
  background-image: url(./assets/logo-yakinku.png);
  margin: 20rem 16rem;
  margin-bottom: 40rem;
}

.booking-phone {
  position: relative;
  margin-bottom: 20rem;

  .booking-phone-error {
    position: absolute;
    bottom: -18rem;
    color: var(--error-color);
  }
}
