.que-title {
  padding: 20rem;
  font-weight: 700;
  font-size: 18rem;
  text-align: center;
  flex: 1;
}

.que-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30rem;
  margin-bottom: 4rem;

  .que-title {
    padding: 0;
    text-align: start;
  }

  .que-est {
    font-size: 10rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .que-time {
    border-radius: 5rem;
    background-color: #fed130;
    font-weight: 700;
    font-size: 18rem;
    text-align: center;
  }
}

.que-note {
  font-size: 8rem;
  text-align: center;
  margin-top: 16rem;
  padding: 0 90rem;

  span {
    color: var(--blue);
    text-decoration: underline;
    cursor: pointer;
  }
}

.que-take-btn-wrap {
  display: flex;
  height: calc(100dvh - 70rem);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .que-take-btn {
    margin: 30rem 0;
  }
}
