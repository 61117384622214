.error-modal-container {
  padding: 0 !important;
  width: min(400rem, 80vw);

  .error-modal-main {
    font-size: 16rem;
    padding: 30rem 18rem;
    font-size: 14rem;
    color: var(--black);
    text-align: center;
  }

  .customized-button {
    flex: 1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
