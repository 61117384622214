@font-face {
  font-family: "CustomizedFont";
  src: url("../Assets/Fonts/Bebeboo-Regular.ttf") format("truetype");
}

:root {
  --transition: all 0.2s linear;
  --font: "CustomizedFont", sans-serif;
  --size: 13rem;

  --black: #333333;
  --blue: #0000ee;
  --white: #f0f0f0;
  --error-color: #ff0000;

  // Button
  --radius: 5rem;
  --color: #ffffff;
  --background: #991f23;
  --hover: #991f23;
  --active: #39819d;
  --disabled: #e9eef6;
  --disabled-color: #727c8a;

  --cancel-background: #f3f7fc;
  --cancel-hover: #263434;
  --cancel-active: #e9eef6;

  // Select
  --border: #bdc3cb;
  --select-button-background: white;
  --select-hover-background: #e3f3f9;
  --select-selected-color: #00bce8;
}
