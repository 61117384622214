.ticket-info {
  margin-top: 20rem;
  background-color: var(--background);
  border-top-left-radius: 15rem;
  border-top-right-radius: 15rem;
  padding: 15rem;
  width: 300rem;

  .ticket-number {
    text-align: center;
    font-size: 48rem;
    font-weight: 700;
    color: white;
  }

  .ticket-time {
    text-align: center;
    font-size: 12rem;
    color: white;
  }
}

.ticket-note {
  padding: 13rem 15rem;
  width: 298rem;
  border: 1rem solid var(--background);
  border-bottom-left-radius: 15rem;
  border-bottom-right-radius: 15rem;

  ul {
    margin: 0;
    padding: 16rem;
  }

  li {
    &:last-child {
      color: var(--error-color);
    }
  }
}
