.customized-button {
  cursor: pointer;
  border: 1rem solid var(--background);
  background-color: var(--background);
  border-radius: var(--radius);
  color: var(--color);
  transition: var(--transition);
  font-size: 14rem;
  font-weight: 700;
  height: 40rem;
  width: 320rem;

  &:hover {
    border: 1rem solid var(--hover);
    background-color: var(--hover);
  }

  &:active {
    border: 1rem solid var(--active);
    background-color: var(--active);
  }

  &:disabled {
    border: 1rem solid var(--disabled);
    background-color: var(--disabled);
    color: var(--disabled-color);
    cursor: not-allowed;
  }
}
