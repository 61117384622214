.que-table-wrap {
  padding: 0 30rem;
}

.que-table {
  padding: 0 40rem;
  width: calc(100vw - 80rem);
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1rem solid black;
  }

  th {
    background-color: black;
    color: white;
    padding: 10rem;
    font-weight: 700;
    font-size: 16rem;
  }

  td {
    height: 40rem;
    padding: 10rem;
    text-align: center;
    font-weight: 700;
    font-size: 14rem;
  }
}
