.term-title {
  font-weight: bold;
  text-align: center;
  font-size: 20rem;
  margin-bottom: 20rem;
}

.term {
  padding: 0;
  margin: 0;
  margin-left: 30rem;

  li {
    text-align: justify;
    margin-bottom: 10rem;
    font-size: 16rem;
  }
}
