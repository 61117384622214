.count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 90rem;

  .count-btn {
    height: 40rem;
    width: 40rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .count-minus {
      mask-image: url(./assets/minus.svg);
      background-color: var(--color);
      width: 30rem;
    }

    .count-add {
      mask-image: url(./assets/add.svg);
      background-color: var(--color);
      width: 30rem;
    }
  }

  .count-text {
    font-size: 36rem;
    font-weight: 700;
  }
}
